import loadable from '@loadable/component';

export const AnalyticsIcon = loadable(() => import('./analytics.svg'));
export const AuditDataIcon = loadable(() => import('./audit-data.svg'));
export const AuditTrailIcon = loadable(() => import('./audit-trail.svg'));
export const BackupRecoverIcon = loadable(() => import('./backup-recover.svg'));
export const GitBranchIcon = loadable(() => import('./git-branch.svg'));
export const HealthCheckIcon = loadable(() => import('./health-check.svg'));
export const MultiManifestIcon = loadable(() => import('./multi-manifest.svg'));
export const RBACIcon = loadable(() => import('./rbac.svg'));
export const SynchronizeIcon = loadable(() => import('./synchronize.svg'));

export const DefineWorkflowsIcon = loadable(
  () => import('./ArgoWorkflows/define-workflows.svg')
);
export const IntensiveJobsMLIcon = loadable(
  () => import('./ArgoWorkflows/intensive-jobs-for-ml.svg')
);
export const MultiStepsWorkflowIcon = loadable(
  () => import('./ArgoWorkflows/multi-steps-workflow.svg')
);

export const ArbitraryBooleanLogicIcon = loadable(
  () => import('./ArgoEvents/arbitrary-boolean-logic.svg')
);
export const ConstraintLogicIcon = loadable(
  () => import('./ArgoEvents/business-level-constraint-logic.svg')
);
export const CustomEventSourceListenerIcon = loadable(
  () => import('./ArgoEvents/custom-event-source-listener.svg')
);

export const AutomatedRollbacksIcon = loadable(
  () => import('./ArgoRollouts/automated-rollbacks.svg')
);
export const BlueGreenCanaryIcon = loadable(
  () => import('./ArgoRollouts/blue-green-canary.svg')
);
export const CustomizableMetricsIcon = loadable(
  () => import('./ArgoRollouts/customizable-metrics.svg')
);
export const IngressControllerIcon = loadable(
  () => import('./ArgoRollouts/integrate-ingress-controller.svg')
);
export const ThirdPartyIcon = loadable(
  () => import('./ArgoRollouts/integrate-third-party.svg')
);
export const TrafficShiftingIcon = loadable(
  () => import('./ArgoRollouts/traffic-shifting.svg')
);
