import { useEffect } from 'react';

type ThinkificEmbederProps = {
  id: string;
  courseURL: string;
  center?: boolean;
};

export const ThinkificEmbeder = ({
  id,
  courseURL,
  center
}: ThinkificEmbederProps) => {
  useEffect(() => {
    const mainDiv = document.querySelector(`.thinkific-product-card`);

    if (!mainDiv) {
      return;
    }

    const iframeContainer = document.createElement('div');
    iframeContainer.setAttribute('class', 'iframe-container');

    const thinkificEmbed = document.createElement('script');
    thinkificEmbed.setAttribute('id', 'thinkific-product-embed');
    thinkificEmbed.setAttribute('type', 'text/javascript');
    thinkificEmbed.innerHTML = `
    !function() {
      function t(t) {
          if (t && t.data && t.data.split) {
              var e = t.data.split("|");
              if (e && e.length && 3 === e.length) {
                  var n = e[0] + "px"
                    , r = e[1]
                    , o = e[2]
                    , i = r.indexOf(t.origin);
                  if (-1 != i) {
                      var a = "thinkific-product-embed-iframe-" + o
                        , d = document.getElementById(a);
                      d && (d.style.height = n,
                      d.style.maxWidth = "230px")
                  }
              }
          }
      }
      function e(t, e) {
          var n = "button" === t.cardType ? "28" : "245"
            , r = document.createElement("iframe");
          return r.src = t.iframeUrl(),
          r.id = "thinkific-product-embed-iframe-" + e,
          r.frameBorder = "0",
          r.height = n,
          r.width = "230",
          r.scrolling = "no",
          r.allowtransparency = "true",
          r
      }
      function n(t) {
          for (var e = 0; e < t.children.length; e++)
              if ("iframe-container" === t.children[e].className)
                  return t.children[e];
          return null
      }
      function r(t, r) {
          var o = n(t);
          if (o) {
              t.setAttribute("data-embed-index", r);
              var i = {
                  btnTxt: t.getAttribute("data-btn-txt"),
                  btnBgColor: t.getAttribute("data-btn-bg-color"),
                  btnTxtColor: t.getAttribute("data-btn-txt-color"),
                  cardBgColor: t.getAttribute("data-card-bg-color"),
                  cardTextColor: t.getAttribute("data-card-txt-color"),
                  linkType: t.getAttribute("data-link-type"),
                  cardType: t.getAttribute("data-card-type"),
                  storeUrl: t.getAttribute("data-store-url"),
                  productId: t.getAttribute("data-product"),
                  embedVersion: t.getAttribute("data-embed-version"),
                  ref: t.getAttribute("data-ref"),
                  iframeUrl: function() {
                      var t = ["button_text=" + encodeURIComponent(this.btnTxt), "button_bg_color=" + encodeURIComponent(this.btnBgColor), "button_text_color=" + encodeURIComponent(this.btnTxtColor), "card_bg_color=" + encodeURIComponent(this.cardBgColor), "card_text_color=" + encodeURIComponent(this.cardTextColor), "card_type=" + encodeURIComponent(this.cardType), "product_id=" + encodeURIComponent(this.productId), "link_type=" + encodeURIComponent(this.linkType), "embed_version=" + encodeURIComponent(this.embedVersion), "index=" + encodeURIComponent(r)];
                      return null !== this.ref && t.push("ref=" + encodeURIComponent(this.ref)),
                      t = t.join("&"),
                      -1 !== this.storeUrl.indexOf("?") ? this.storeUrl + "&" + t : this.storeUrl + "?" + t
                  }
              }
                , a = e(i, r);
              o.appendChild(a)
          }
      }
      function o(t) {
          if (document.getElementsByClassName)
              return document.getElementsByClassName(t);
          if (t = t.replace(/ *$/, ""),
          document.querySelectorAll)
              return document.querySelectorAll((" " + t).replace(/ +/g, "."));
          t = t.replace(/^ */, "");
          var e, n, r, o = t.split(/ +/), i = o.length, a = document.getElementsByTagName("*"), d = a.length, c = [];
          for (e = 0; d > e; e++) {
              for (r = !0,
              n = i; n--; )
                  RegExp(" " + o[n] + " ").test(" " + a[e].className + " ") || (r = !1);
              r && c.push(a[e])
          }
          return c
      }
      window.addEventListener && window.addEventListener("message", t, !1);
      var i = function(t, e, n) {
          e = document,
          n = "addEventListener",
          e[n] ? e[n]("DOMContentLoaded", t) : window.attachEvent("onload", t)
          t();
        };
      i(function() {
          var t = o("thinkific-product-card");
          if (t && t.length)
              for (var e = 0; e < t.length; e++)
                  r(t[e], e)
      })
  }();
    `;
    mainDiv.append(iframeContainer, thinkificEmbed);

    const noScript = document.createElement('noscript');
    noScript.innerHTML = `
      <a
      href="https://academy.akuity.io/courses/gitops-argocd-intro"
      target="_blank"
      rel="noreferrer"
    >
      Enroll now
    </a>
      `;

    mainDiv.append(noScript);

    const embeder = document.querySelector(`.embed-thinkific-here-${id}`);

    embeder?.append(mainDiv);

    return () => {
      mainDiv.remove();
    };
  }, []);

  return (
    <div className={`embed-thinkific-here-${id}`}>
      <div
        className="thinkific-product-card"
        data-btn-txt="Enroll now"
        data-btn-txt-color="#ffffff"
        data-btn-bg-color="#454863"
        data-card-type="card"
        data-link-type="landing_page"
        data-product="2284008"
        data-embed-version="0.0.2"
        data-card-txt-color="#7d7d7d"
        data-card-bg-color="#ffffff"
        data-store-url={courseURL}
        style={
          center
            ? {
                display: 'block',
                width: 'fit-content',
                margin: '0 auto'
              }
            : {}
        }
      />
    </div>
  );
};
