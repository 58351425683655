import { Button } from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import './enterprise-cta.scss';

export const EnterpriseCTA = () => (
  <div className="no-global-padding akuity-cloud-cta-wrapper cta-wrapper">
    <div className="padding-global cta-container">
      <PageHeader
        textPrimary={{
          text: 'Interested in a Cloud Solution for Argo?',
          color: 'default',
          className: 'header'
        }}
        textSecondary={{
          text: 'Check out the Akuity Platform: simpler and safer Kubernetes application delivery, using Argo.',
          color: 'default',
          className: 'subheader'
        }}
      />
      <div className="cta-button">
        <Button
          additionalClass="button-hover-color--primary-light"
          link="/akuity-platform"
        >
          Learn more about the Akuity Platform
        </Button>
      </div>
    </div>
  </div>
);
