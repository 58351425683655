import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useGetImage } from '../index';
import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { ThinkificEmbeder } from '@/components/ThinkificEmbeder';
import { Typography } from '@/components/Typography';
import { EnterpriseCTA } from '@/sections/Enterprise/components/EnterpriseCTA';
import {
  AnalyticsIcon,
  ArbitraryBooleanLogicIcon,
  AuditDataIcon,
  AuditTrailIcon,
  AutomatedRollbacksIcon,
  BackupRecoverIcon,
  BlueGreenCanaryIcon,
  ConstraintLogicIcon,
  CustomEventSourceListenerIcon,
  CustomizableMetricsIcon,
  DefineWorkflowsIcon,
  GitBranchIcon,
  HealthCheckIcon,
  IngressControllerIcon,
  IntensiveJobsMLIcon,
  MultiManifestIcon,
  MultiStepsWorkflowIcon,
  RBACIcon,
  SynchronizeIcon,
  ThirdPartyIcon,
  TrafficShiftingIcon
} from '@/static/assets/icons/ArgoPage';
import ArgoEvents from '@/static/assets/images/ArgoPage/argo-events.png';
import ArgoLogo from '@/static/assets/images/ArgoPage/argo-header-logo.png';
import ArgoCDDashboard from '@/static/assets/images/ArgoPage/argocd-dashboard.png';
import RolloutsDashboard from '@/static/assets/images/ArgoPage/rollouts-dashboard.png';
import WorkflowsDAG from '@/static/assets/images/ArgoPage/workflows-dag.png';
import './what-is-argo.scss';
import { seoConfiguration } from '@/utils/seo-config';

export const useSlider = (
  totalSlides: number
): [number, React.Dispatch<React.SetStateAction<number>>, number[]] => {
  const slides = totalSlides;

  const [selectedSlider, setSelectedSlider] = useState(1);

  const iterator = new Array(slides).fill(0);

  return [selectedSlider, setSelectedSlider, iterator];
};

const ArgoPage = () => {
  const [headerSlider, setHeaderSlider, headerSliderIterator] = useSlider(
    content.information.length
  );

  const dashboardImages = [
    useGetImage('argocd-dashboard.png', {
      default: {
        width: 700,
        height: 442
      },
      desktop: {
        width: 650,
        height: 410
      },
      mobile: {
        width: 300,
        height: 189
      }
    }),
    useGetImage('workflows-dag.png', {
      default: {
        width: 700,
        height: 442
      },
      desktop: {
        width: 650,
        height: 410
      },
      mobile: {
        width: 300,
        height: 189
      }
    }),
    useGetImage('rollouts-dashboard.png', {
      default: {
        width: 700,
        height: 610
      },
      desktop: {
        width: 650,
        height: 567
      },
      mobile: {
        width: 300,
        height: 261
      }
    }),
    useGetImage('argo-events.png', {
      default: {
        width: 700,
        height: 496
      },
      desktop: {
        width: 600,
        height: 425
      },
      mobile: {
        width: 300,
        height: 213
      }
    })
  ];

  return (
    <Layout {...content.seo}>
      <div className="what-is-argo-page">
        <div className="argo-page-header">
          <PageHeader
            classTextColumn="col-sm-12 col-md-6 col-xl-6 page-header__text-colum--padding"
            textPrimary={{
              text: content.header,
              color: 'akuity-purple'
            }}
            textSecondary={{
              text: content.subheader
            }}
            textDescription={{
              text: (
                <Typography
                  typographyKind="text-primary-medium-regular"
                  component="p"
                >
                  {content.descriptionParagraphs.map((desc, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        {desc}
                        {idx !== content.descriptionParagraphs.length - 1 ? (
                          <>
                            <br />
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              )
            }}
            image={<img src={ArgoLogo} alt="argocd logo" className="image" />}
            classImageColumn="col-sm-12 col-md-5 col-xl-5"
          />
        </div>

        <ThinkificEmbeder
          id="what-is-argo-page-after-what-is-argo-section"
          courseURL="https://academy.akuity.io/embeds/products/show"
          center
        />

        <div className="slider-wrapper">
          {headerSliderIterator.map((_, idx) => (
            <div
              key={idx}
              className={`slider ${idx + 1 === headerSlider ? 'active' : ''}`}
              onClick={() => setHeaderSlider(idx + 1)}
            />
          ))}
        </div>

        <div className="argo-page-information">
          <PageHeader
            textPrimary={{
              text: content.information[headerSlider - 1].header
            }}
            textSecondary={{
              text: content.information[headerSlider - 1].subheader
            }}
            textDescription={{
              text: content.information[headerSlider - 1].description,
              typographyKind: 'text-primary-medium-regular'
            }}
          />

          <div className="slider-with-image">
            <GatsbyImage
              image={dashboardImages[headerSlider - 1]}
              alt="argo product dashboard"
            />
          </div>
        </div>

        <div className="argo-page-key-features">
          <Typography
            component="h2"
            typographyKind="header-secondary-large-bold"
            className="text-align-center"
          >
            Key features of {content.information[headerSlider - 1].header}
          </Typography>

          <div className="features-wrapper row">
            {content.information[headerSlider - 1].features.map((feature) => (
              <div key={feature.text} className="feature col-md-6 col-lg-4">
                <feature.icon className="feature-icon" />
                <Typography
                  typographyKind="header-secondary-small-bold"
                  component="h2"
                >
                  {feature.text}
                </Typography>
              </div>
            ))}
          </div>

          <div className="start-here-wrapper">
            <Button
              additionalClass="start-here button-hover-color--secondary"
              link={content.information[headerSlider - 1].CTA.link}
              isExternalLink
            >
              {content.information[headerSlider - 1].CTA.text}
            </Button>
          </div>
        </div>

        <EnterpriseCTA />
      </div>
    </Layout>
  );
};

const content = {
  seo: seoConfiguration['what-is-argo'],
  header: 'What is Argo?',
  subheader:
    'Kubernetes tooling to deploy applications, manage clusters, run workflows, and do GitOps right.',
  descriptionParagraphs: [
    `The Argo Project is a suite of open source tools for deploying and running applications and workloads on Kubernetes. It extends the Kubernetes APIs and unlocks new and powerful capabilities in application deployment, container orchestration, event automation, progressive delivery, and more.`,
    `The project was created by Akuity co-founders and is a graduated Cloud Native Computing Foundation (CNCF) project. Trusted in production by leading enterprises globally.`
  ],
  information: [
    {
      header: 'Argo CD',
      subheader:
        'A declarative, GitOps application delivery tool for Kubernetes.',
      description:
        'Manage application definitions, configurations, and environments declaratively in Git. Argo CD user experience makes Kubernetes application deployment and lifecycle management automated, auditable, and easy to grasp.',
      image: ArgoCDDashboard,
      features: [
        {
          icon: HealthCheckIcon,
          text: `Perform quick health checks on all components of applications.`
        },
        {
          icon: AnalyticsIcon,
          text: `Display actionable analytics regarding deployment patterns, including deployment stats, cluster telemetry, and more.`
        },
        {
          icon: GitBranchIcon,
          text: `Track git branches, pin relevant tags and commits.`
        },
        {
          icon: SynchronizeIcon,
          text: `Synchronize with git manually or automatically.`
        },
        {
          icon: RBACIcon,
          text: `Introduce role-based access control and SSO integration.`
        },
        {
          icon: AuditTrailIcon,
          text: `Audit trail and history of application events and API calls.`
        },
        {
          icon: MultiManifestIcon,
          text: `Use multiple manifests template formats`
        },
        {
          icon: BackupRecoverIcon,
          text: `Backup and Recover`
        },
        {
          icon: AuditDataIcon,
          text: `Audit data and store it long-term`
        }
      ],
      CTA: {
        text: 'Start here',
        link: 'https://argoproj.github.io/cd'
      }
    },
    {
      header: 'Argo Workflows',
      subheader:
        'A container-native workflow engine for orchestrating parallel jobs, implemented as Kubernetes CRD.',
      description:
        'Run jobs and full workflows on any Kubernetes cluster. Orchestrate highly parallel jobs without the overhead of legacy VMs or server environments.',
      image: WorkflowsDAG,
      features: [
        {
          icon: DefineWorkflowsIcon,
          text: `Define workflows where each step is a separate container.`
        },
        {
          icon: MultiStepsWorkflowIcon,
          text: `Model multi-step workflows as a sequence of tasks or capture the dependencies between tasks using a graph (DAG).`
        },
        {
          icon: IntensiveJobsMLIcon,
          text: `Run compute-intensive jobs for machine learning or data processing`
        }
      ],
      CTA: {
        text: 'Start here',
        link: 'https://argoproj.github.io/argo-workflows/quick-start/'
      }
    },
    {
      header: 'Argo Rollouts',
      subheader:
        'A Kubernetes controller and set of CRDs which provides progressive deployment capabilities.',
      description:
        'Introduce blue-green and canary deployments, canary analysis, experimentation, and progressive delivery features to your Kubernetes cluster.',
      image: RolloutsDashboard,
      features: [
        {
          icon: BlueGreenCanaryIcon,
          text: `Implement Blue-Green or Canary update strategy`
        },
        {
          icon: TrafficShiftingIcon,
          text: `Create fine-grained traffic shifting strategies`
        },
        {
          icon: AutomatedRollbacksIcon,
          text: `Use automated rollbacks and promotions out-of-the-box`
        },
        {
          icon: CustomizableMetricsIcon,
          text: `View customizable metrics and analyze business KPIs`
        },
        {
          icon: IngressControllerIcon,
          text: `Integrate with Ingress controller or service mesh of your choice`
        },
        {
          icon: ThirdPartyIcon,
          text: `Integrate with 3rd party metric providers`
        }
      ],
      CTA: {
        text: 'Start here',
        link: 'https://argoproj.github.io/argo-rollouts/getting-started/'
      }
    },
    {
      header: 'Argo Events',
      subheader: 'An event-based dependency manager for Kubernetes.',
      description:
        'Define multiple dependencies from a variety of event sources like webhooks, S3, schedules, streams, and trigger Kubernetes objects after successful event dependencies resolution.',
      image: ArgoEvents,
      features: [
        {
          icon: HealthCheckIcon,
          text: `Manage dependencies from a variety of event sources`
        },
        {
          icon: ConstraintLogicIcon,
          text: `Customize business-level constraint logic for event dependencies resolution`
        },
        {
          icon: CustomEventSourceListenerIcon,
          text: `Extend the framework by adding your own event source listener`
        },
        {
          icon: ArbitraryBooleanLogicIcon,
          text: `Define arbitrary boolean logic to resolve event dependencies`
        }
      ],
      CTA: {
        text: 'Start here',
        link: 'https://argoproj.github.io/argo-events/quick_start'
      }
    }
  ]
};

export default ArgoPage;
